import React from "react"

import { useRoutes } from "../../hooks/useRoutes"

export const withTextGrid = Component => ({ name = "TextGrid", link, ...rest }) => {
  const { linkResolver } = useRoutes()
  const { url: buttonUrl, title: buttonText } = linkResolver(link) || {}

  Component.displayName = name
  return <Component buttonUrl={buttonUrl} buttonText={buttonText} {...rest} />
}
