import React, { useState } from "react"

import { useImage } from "../../hooks/useImage"

export const withMaintenance = Component => ({ name = "Maintenance", page, location }) => {
  const { getFluidImage } = useImage()
  const [active, setActive] = useState(false)

  const { title, subtitle, content, link, image: originalImage } = page

  const image = getFluidImage(originalImage)

  const handleClick = () => setActive(prev => !prev)

  Component.displayName = name
  return (
    <Component
      title={title}
      subtitle={subtitle}
      content={content}
      link={link}
      image={image}
      location={location}
      active={active}
      handleClick={handleClick}
    />
  )
}
