import React from "react"

import { withMaintenance } from "./withMaintenance"
import { Outer, Inner, TextGridWrapper } from "./MaintenanceStyles"
import { MaintenanceForm } from "./Form/MaintenanceForm"
import { Main } from "../Main/Main"
import { Image } from "../Image/Image"
import { TextGrid } from "../TextGrid/TextGrid"

export const Maintenance = withMaintenance(
  ({ title, subtitle, content, link, image, location, handleClick, active }): JSX.Element => (
    <Main>
      <Outer>
        <Inner center={"true"}>
          <TextGridWrapper>
            <TextGrid title={title} titleSize={"sm"} subtitle={subtitle} content={content} link={link} handleClick={handleClick} />
            {active ? <MaintenanceForm location={location} /> : null}
          </TextGridWrapper>
        </Inner>
        <Inner>
          <Image image={image} fullHeight />
        </Inner>
      </Outer>
    </Main>
  )
)
