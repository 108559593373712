import React from "react"
import { graphql } from "gatsby"

import { Maintenance as Page } from "../components/Maintenance/Maintenance"

export const query = graphql`
  query {
    page: sanitySettingMaintenance {
      title
      password
      enabled
      subtitle
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      link: _rawLink(resolveReferences: { maxDepth: 2 })
      image: _rawBackgroundImage(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
