import React from "react"

import { withTextGrid } from "./withTextGrid"
import { Wrapper, Title, Subtitle, RichTextWrapper } from "./TextGridStyles"
import { StyledButton } from "../Styled/Button"
import { Link } from "gatsby"
import { RichText } from "../RichText/RichText"

export const TextGrid = withTextGrid(({ title, titleSize, subtitle, content, buttonUrl, buttonText, handleClick }) => (
  <Wrapper>
    {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    {title ? <Title size={titleSize}>{title}</Title> : null}
    {content ? (
      <RichTextWrapper>
        <RichText>{content}</RichText>
      </RichTextWrapper>
    ) : null}
    {buttonText ? (
      <StyledButton as={buttonUrl ? Link : null} onClick={handleClick} to={buttonUrl} size={"tertiary"} colour={"red"}>
        {buttonText}
      </StyledButton>
    ) : null}
  </Wrapper>
))
