import tw, { styled } from "twin.macro"

export const Outer = tw.div`
  flex flex-col-reverse md:flex-row min-h-full-vh
`

export const Inner = styled.div<InnerProps>`
  ${tw`w-full md:w-1/2`}
  ${({ center }) => (center === "true" ? tw`flex items-center md:justify-center` : null)}
`

export const TextGridWrapper = tw.div`
  flex flex-col mb-6
`

type InnerProps = {
  center?: "true"
}
