import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`pt-4 px-2-4 max-w-45`

export const Title = styled.h2<TitleProps>`
  ${tw`uppercase pb-3 break-words`}
  ${({ size }) => {
    if (size === "lg") return tw`text-40 md:text-50 leading-1.2 tracking-2 font-bold`
    if (size === "sm") return tw`text-28 md:text-35 leading-1.28 tracking-3 font-bold`
  }}
`

export const Subtitle = styled.h3`
  ${tw`text-14 md:text-16 font-medium tracking-14 text-red flex items-center leading-none mb-2-4 md:mb-3-2 uppercase`}
  ::before {
    content: "";
    ${tw`border-t border-red w-1-8 h-0-1 mr-1-6`}
  }
`

export const RichTextWrapper = tw.div`
  mb-3
`

type TitleProps = {
  size: "lg" | "sm"
}
